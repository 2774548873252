import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import TopContainer from "./TopContainer";
import PolicyContainer from "./PolicyContainer";
import StanceContainer from "./StanceContainer";
import ProfileContainer from "./ProfileContainer";
import WorksContainer from "./WorksContainer";
import ContactContainer from "./ContactContainer";
import { useNavigate }  from "react-router-dom";
import useLocationChange from './useLocationChange'
import Head from "./Head";

const Home =() => {
  const [title, setTitle]  = useState("デザインくれは");
  const [firstAccess, setFirstAccess]  = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [locationPath, setLocationPath] = useState<string>("/");
  const [showMenu, setShowMenu]  = useState(false);
  const handleSetLocationPath = (path: any): void => setLocationPath(path);
  const handleSetNaviatePath = (path: any): void => navigate(path);
  const handleSetShowMenu = (path: any): void => setShowMenu(path);
  useLocationChange((location) => {
        const path = location.pathname;
        if(firstAccess) {
          setFirstAccess(false);
        } else {//最初以外はパスの判断でshowMenuする
          setShowMenu(path == "/");
        }
        setLocationPath(path);
        setTitle(getTitle(path));
  })
  const getTitle = (path: string) => {
    const baseTitle = "デザインくれは　鹿児島市　クリエイティブ（グラフィックデザイナー）　広告　通販　企画　デザイン"
    if(path == "/policy"){
      return "POLICY | " + baseTitle;
    } else if(path == "/stance"){
      return "STANCE | " + baseTitle
    } else if(path == "/profile"){
      return "PROFILE | " + baseTitle
    } else if(path == "/works"){
      return "WORKS | " + baseTitle
    } else if(path == "/contact"){
      return "CONTACT | " + baseTitle
    }
      return baseTitle
  }
  useEffect(() => {
    //setLocationPath(location.pathname);
      //if(location.pathname === "/") {
      //  console.log("top");
      //}
  }, []);
    //
  return (
    <>
      <Head title={title}/>
      <PolicyContainer locationPath={locationPath} setLocationPath={handleSetLocationPath} navigate={handleSetNaviatePath} />
      <StanceContainer locationPath={locationPath} setLocationPath={handleSetLocationPath} navigate={handleSetNaviatePath} />
      <ProfileContainer locationPath={locationPath} setLocationPath={handleSetLocationPath} navigate={handleSetNaviatePath} />
      <WorksContainer locationPath={locationPath} setLocationPath={handleSetLocationPath} navigate={handleSetNaviatePath} />
      <ContactContainer locationPath={locationPath} setLocationPath={handleSetLocationPath} navigate={handleSetNaviatePath} />
      <TopContainer navigate={handleSetNaviatePath} showMenu={showMenu} setShowMenu={handleSetShowMenu}/>
    </>
  );
}
export default Home;
