import React, { useRef, useState, useEffect } from "react";

//const TopMenu =  ({showMenu = false,  }) => {
//
//  return (
//    <div className={showMenu ? "top-menu show" : "top-menu"} >menu</div>
//  );
//}

const TopAnimation = ({setShowMenu = () => {},  setTextAnimationPlayParent = (flg: any) => {},  setTextAnimationBeige = (flg: any) => {},  setTextAnimationMov = (flg: any) => {},  setUseAnimation = (flg: any) => {}}) => {

  const [first, setFirst]  = useState(false);
  const [play, setPlay]  = useState(false);
  const [textAnimationPlay, setTextAnimationPlay]  = useState(false);
  const [logoAnimationPlay, setLogoAnimationPlay]  = useState(false);
  useEffect(() => {
    if(play==false && first == false) {
      setFirst(true);
      setPlay(true);
      setTextAnimationPlay(true);
    //setLogoAnimationPlay(true);
    }
    
  }, []);

  const textAnimationEnd = () =>  {
    setTextAnimationPlayParent(false);
    setTextAnimationBeige(false);
    setTextAnimationMov(false);
    setTextAnimationPlay(false);
    setLogoAnimationPlay(true);
  }
  const textAnimation09End = () =>  {
    setTextAnimationPlayParent(false);
    setTextAnimationMov(true);
  }
  const LogoAnimationEnd = () => {
    setUseAnimation(false);
    setShowMenu();
  }

  return (
    <div className={play ? 'top-animation play' : 'top-animation' } >
      <div className={textAnimationPlay ? "top-animation-text play" :"top-animation-text"}>
         <LogoAnimation className={"indexPhrase indexPhrase--01 indexPhrase--A"} onAnimationEndCallback={() => setTextAnimationPlayParent(true)}/>
         <LogoAnimation className={"indexPhrase indexPhrase--b01 indexPhrase--B1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--b02 indexPhrase--B2"} onAnimationEndCallback={() => setTextAnimationBeige(true)} />
         <LogoAnimation className={"indexPhrase indexPhrase--b03 indexPhrase--B3"} onAnimationEndCallback={() => setTextAnimationPlayParent(false)}/>
         <LogoAnimation className={"indexPhrase indexPhrase--15 indexPhrase--C1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--16 indexPhrase--C2"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--17 indexPhrase--D1"} onAnimationEndCallback={() => setTextAnimationPlayParent(true)}/>
         <HtmlAnimation className={"indexPhrase indexPhrase--cover indexPhrase--D2"} html={'<img src="img/パターンD02.png">'} onAnimationEndCallback={() => setTextAnimationBeige(false)} />
         <LogoAnimation className={"indexPhrase indexPhrase--e01 indexPhrase--E1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--e02 indexPhrase--E2"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--e03 indexPhrase--E3"} onAnimationEndCallback={() => setTextAnimationPlayParent(true)}/>
         <LogoAnimation className={"indexPhrase indexPhrase--08 indexPhrase--F1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--09 indexPhrase--F2"} onAnimationEndCallback={() => textAnimation09End()}/>
         <LogoAnimation className={"indexPhrase indexPhrase--10 indexPhrase--H1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--11 indexPhrase--H2"} onAnimationEndCallback={() => setTextAnimationMov(false)}/>
         <LogoAnimation className={"indexPhrase indexPhrase--12 indexPhrase--J1"}/>
         <LogoAnimation className={"indexPhrase indexPhrase--13 indexPhrase--J2"} onAnimationEndCallback={() => textAnimationEnd()}/>
         <div className="indexSkip" onClick={() => textAnimationEnd()}></div>
      </div>
      <div className={logoAnimationPlay ? "indexLogo top-animation-logo play" :"indexLogo top-animation-logo"}>
         <LogoAnimation className={"indexLogo--c"}/>
         <LogoAnimation className={"indexLogo--r"}/>
         <LogoAnimation className={"indexLogo--e"}/>
         <LogoAnimation className={"indexLogo--h"}/>
         <LogoAnimation className={"indexLogo--a"}/>
         <LogoAnimation className={"indexLogo--design"}/>
         <LogoAnimation className={"indexLogo--creation"} onAnimationEndCallback={() => LogoAnimationEnd()}/>
      </div>
      { false &&
      <>
      </>
      }
    </div>
  );
}

const HtmlAnimation = ({className = "", html = "", onAnimationEndCallback = () => {}}) => {
  useEffect(() => {
    //console.log(onAnimationEnd);
  }, []);

  return (
    className == "" && html == ""
      ? <div />
      : <div className={className} dangerouslySetInnerHTML={{__html: html}} onAnimationEnd={onAnimationEndCallback} />
  );
}

const LogoAnimation = ({className = "", onAnimationEndCallback = () => {}}) => {
  useEffect(() => {
    //console.log(onAnimationEnd);
  }, []);

  return (
    className == ""
      ? <div />
      : <div className={className} onAnimationEnd={onAnimationEndCallback} ></div>
  );
}


export default TopAnimation;
