import React, { useRef, useState, useEffect } from "react";
import { useNavigate }  from "react-router-dom";



const PolicyContainer =  ({show=false, locationPath = "/", setLocationPath = (path: any) => {} , navigate = (path: any) => {} }) => {
  const [hidePage, setHidePage]  = useState(false);
  function returnTop() {
    setHidePage(true);
  }
  function removeHide(elm: any) {
    if(elm.target.classList.contains("main") && hidePage) {
      navigate("/");
      setHidePage(false);
    }
  }

  return (
      <div className={"container container-policy" + (locationPath === "/policy" ? " show" : "") + (hidePage ? " hide" : "" )}>
        <div className="side">
          <div className="sideLogo" onClick={() => returnTop()}><img src="img/sidelogo.png" alt="design creha" /></div>
          <div className="sideCatch sideCatch__policy"><img src="img/copy__policy.png" alt="「用と美」の調和" /></div>
          <div className="sideCopy">© Design CREHA all right reserved.</div>
        </div>
        <div className="main main__policy" onAnimationEnd={(e) => removeHide(e)}>
          <div className="mainClose" onClick={() => returnTop()}>
            <span></span>
            <span></span>
          </div>
          <div className="mainHead">POLICY</div>
          <div className="policyBlock">
            <h2 className="policyHead">デザインへの想い</h2>
            <p className="policyDesc">「用と美」という言葉があります。<br />「用」が機能的、「美」が視覚的ということになるのですが、このふたつが互いに役割を果たすことができて初めて、生活者に受け入れられるデザインになるのだと思います。<br />もちろん、『グラフィックデザイン = 視覚デザイン』と言われるように視覚的な要素は大前提なのですが、ただ単に美しいだけでは生活者との相互コミュニケーションは図れません。「何がしたい」、「これをやりたい」の根幹となるコンセプトを見出し、それを確実に伝えることができる機能が備わっていなければデザイン的に疑問が残ります。<br />私たちは「用 = コンセプト」と「美 = ビジュアル」ふたつの要素が調和し合い、クライアントと生活者がしっかりとコミュニケーションできるデザインを目指しています。</p>
          </div>
         </div>
         </div>
  );
}

export default PolicyContainer;
