import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";

export const RouterConfig = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="policy" element={<Home />} />
          <Route path="stance" element={<Home />} />
          <Route path="profile" element={<Home />} />
          <Route path="works" element={<Home />} />
          <Route path="contact" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
