import React, { useRef, useState, useEffect } from "react";

//const TopMenu =  ({showMenu = false,  }) => {
//
//  return (
//    <div className={showMenu ? "top-menu show" : "top-menu"} >menu</div>
//  );
//}

const TopAnimationComplete = ({setShowMenu = () => {},  setTextAnimationPlayParent = (flg: any) => {},  setTextAnimationMov = (flg: any) => {}}) => {


  const [first, setFirst]  = useState(false);
  const [play, setPlay]  = useState(false);
  useEffect(() => {
    if(play==false && first == false) {
      setFirst(true);
    }
    
  }, []);

  return (
    <div className={'top-animation'} >
      <div className="indexLogo complete">
        <div className="indexLogo--c"></div>
        <div className="indexLogo--r"></div>
        <div className="indexLogo--e"></div>
        <div className="indexLogo--h"></div>
        <div className="indexLogo--a"></div>
        <div className="indexLogo--design"></div>
        <div className="indexLogo--creation"></div>
      </div>
    </div>
  );
}


export default TopAnimationComplete;
