import React, { useRef, useState, useEffect } from "react";
import { useNavigate }  from "react-router-dom";

const TopMenu =  ({showMenu = false, navigate = (path: any) => {}, setShowMenu = (path: any) => {}, setHideMenu = (path: any) => {}, hideMenu = false  }) => {
  const columnUrl = "https://d-creha.com/blog/"
  function clickLink(path: any) {
    setHideMenu(true);
    setTimeout(function(){
      navigate(path);
    }, 1000);
    setTimeout(function(){
      setHideMenu(false);
    }, 2000);
  }
  return (
    <nav>
      <ul className={"menu top-menu" + (showMenu ? " show" : "") + (hideMenu ? " hide" : "")}>
      <li className="menu--policy" onClick={()=> clickLink("/policy")}>POLICY</li>
      <li className="menu--stance" onClick={()=> clickLink("/stance") }>STANCE</li>
      <li className="menu--profile" onClick={()=> clickLink("/profile") }>PROFILE</li>
      <li className="menu--works" onClick={()=> clickLink("/works") }>WORKS</li>
      <li className="menu--contact" onClick={()=> clickLink("/contact") }>CONTACT</li>
      <li className="menu--column" onClick={()=> window.open(columnUrl)}>COLUMN</li>
      </ul>
    </nav>
  );
}

export default TopMenu;
