import React, { useRef, useState, useEffect } from "react";
import { useNavigate }  from "react-router-dom";



const ContactContainer =  ({show=false, locationPath = "/", setLocationPath = (path: any) => {} , navigate = (path: any) => {} }) => {
  const [hidePage, setHidePage]  = useState(false);
  function returnTop() {
    setHidePage(true);
  }
  function removeHide(elm: any) {
    if(elm.target.classList.contains("mainLogo") && hidePage) {
      navigate("/");
      setHidePage(false);
      setFormComplete(false);
    }
  }
  const [formComplete,setFormComplete] = useState(false);
  const [formDisabled,setFormDisabled] = useState(false);
  const [name,setName] = useState("");
  const onChangeName = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }
  const [furi,setFuri] = useState("");
  const onChangeFuri = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFuri(e.target.value);
  }
  const [company,setCompany] = useState("");
  const onChangeCompany = (e:React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  }
  const [email,setEmail] = useState("");
  const onChangeEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }
  const [emailConfirm,setEmailConfirm] = useState("");
  const onChangeEmailConfirm = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmailConfirm(e.target.value);
  }
  const [tel,setTel] = useState("");
  const onChangeTel = (e:React.ChangeEvent<HTMLInputElement>) => {
    setTel(e.target.value);
  }
  const [content,setContent] = useState("");
  const onChangeContent = (e:React.ChangeEvent<any>) => {
    setContent(e.target.value);
  }
  const [nameError,setNameError] = useState("");
  const [furiError,setFuriError] = useState("");
  const [emailError,setEmailError] = useState("");
  const [emailConfirmError,setEmailConfirmError] = useState("");
  const [telError,setTelError] = useState("");
  const [contentError,setContentError] = useState("");
  const API_URL = "https://d-creha.com/wp-json/contact-form-7/v1/contact-forms/32/feedback";
  const handleSubmit = async (event: any) => {
      event.preventDefault();
      setNameError("");
      setFuriError("");
      setEmailError("");
      setEmailConfirmError("");
      setTelError("");
      setContentError("");
      var formError = false;
      if(name=="") {
        setNameError("お名前を入力してください。");
        formError=true;
      }
      if(furi=="") {
        setFuriError("お名前（フリガナ）を入力してください。");
        formError=true;
      } else if(!furi.match(/^[ァ-ヶー　]+$/)){
        setFuriError("お名前（フリガナ）はフリガナで入力してください。");
        formError=true;
      }
      if(email=="") {
        setEmailError("メールアドレスを入力してください。");
        formError=true;
      } else if(!email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        setEmailError("メールアドレスを正しく入力してください。");
        formError=true;
      }
      if(emailConfirm=="") {
        setEmailConfirmError("メールアドレス（確認用）を入力してください。");
        formError=true;
      } else if(!emailConfirm.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
        setEmailConfirmError("メールアドレス（確認用）を正しく入力してください。");
        formError=true;
      } else if(email != emailConfirm){
        setEmailConfirmError("メールアドレスとメールアドレス（確認用）が一致しません。");
        formError=true;

      }
      if(tel=="") {
        setTelError("電話番号を入力してください。");
        formError=true;
      }
      if(content=="") {
        setContentError("お問い合わせ内容を入力してください。");
        formError=true;
      }
      console.log(formError)
      if(!formError) {
        setFormDisabled(true);
        const formElement = event.target;
        console.log(formElement);
        const body = new FormData(formElement);
        console.log(body);
        try  {
          await fetch(API_URL, {
            method: 'POST',
            body: body,
          }).then((res) => {
            res.json().then(data=> {
              console.log(data);
              if(data["status"] === "mail_sent") {
                console.log("sent")
                setFormDisabled(false);
                setName("");
                setFuri("");
                setCompany("");
                setEmail("");
                setEmailConfirm("");
                setTel("");
                setContent("");
                setFormComplete(true);

              } else {
                setFormDisabled(false);
                console.log("error");
              }
            })
          });
        } catch (e) {
          setFormDisabled(false);
          console.log("error");
        }
      }
  }


  return (
       <>
      {formComplete == false
      ? <div className={"container container__col1 container-contact" + (locationPath === "/contact" ? " show" : "") + (hidePage ? " hide" : "" )} onAnimationEnd={(e) => removeHide(e)}>
        <div className="main main__contact">
          <div className="mainLogo" onClick={() => returnTop()}><img src="img/sidelogo.png" alt="design creha" /></div>
          <div className="mainClose" onClick={() => returnTop()}>
            <span></span>
            <span></span>
          </div>
          <div className="mainHead">CONTACT</div>
          <div className="subHead subHead--contact">デザイン案件のご相談やお見積もりなどお気軽にお問い合わせください。</div>
          <form onSubmit={handleSubmit}>
          <div className="contactBlock">
            <div className="form form--input">
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">お名前</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <input type="text" name="your-name" size={60} value={name} onChange={onChangeName} disabled={formDisabled}/>
                  { nameError != "" &&
                  <span className="error">{nameError}</span>
                  }
                </div>
              </div>
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">お名前（フリガナ）</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <input type="text" name="furigana" size={60} value={furi} onChange={onChangeFuri} disabled={formDisabled} />
                  { furiError != "" &&
                  <span className="error">{furiError}</span>
                  }
                </div>
              </div>
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">貴社名 / 個人の方は不要です。</div>
                </div>
                <div className="form__content"><input type="text" name="company_name" size={60} value={company} onChange={onChangeCompany} disabled={formDisabled} /></div>
              </div>
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">メールアドレス</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <input type="text" name="email" size={60} value={email} onChange={onChangeEmail} disabled={formDisabled} />
                  { emailError != "" &&
                    <span className="error">{emailError}</span>
                  }
                  </div>
              </div>
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">メールアドレス（確認用）</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <input type="text" name="email_confirm" size={60} value={emailConfirm} onChange={onChangeEmailConfirm} disabled={formDisabled}/>
                  { emailConfirmError != "" &&
                    <span className="error">{emailConfirmError}</span>
                  }
                  </div>
              </div>
              <div className="form__item">
                <div className="form__head">
                  <div className="form__title">電話番号</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <input type="text" name="tel" size={60} value={tel} onChange={onChangeTel}  disabled={formDisabled}/>
                  { telError != "" &&
                    <span className="error">{telError}</span>
                  }
                </div>
              </div>
              <div className="form__item form__item--content">
                <div className="form__head">
                  <div className="form__title">お問い合せ内容</div>
                  <div className="form__required">必須</div>
                </div>
                <div className="form__content">
                  <textarea name="content" cols={50} rows={6} onChange={onChangeContent} disabled={formDisabled}>{content}</textarea>
                  { contentError != "" &&
                    <span className="error">{contentError}</span>
                  }
                </div>
              </div>
              <div className="form__btn list">
                <div className="list__item"><input type="submit" name="submitConfirm" value="送信" className="btn btn--form btn--submit" disabled={formDisabled} /></div>
              </div>
            </div>
          </div>
          </form>
          <div className="copy">© Design CREHA all right reserved.</div>
        </div>
      </div>
      :  <div className={"container container__col1 container-contact" + (locationPath === "/contact" ? " show" : "") + (hidePage ? " hide" : "" )} onAnimationEnd={(e) => removeHide(e)}>
          <div className="main main__contact">
            <div className="mainLogo" onClick={() => returnTop()}><img src="img/sidelogo.png" alt="design creha" /></div>
            <div className="mainClose" onClick={() => returnTop()}>
              <span></span>
              <span></span>
            </div>
            <div className="mainHead">CONTACT</div>
            <div className="contactBlock">
              <p className="thanks__head">お問い合わせありがとうございます</p>
              <p className="thanks__desc">この度はお問い合わせをいただき誠にありがとうございます。<br />お問い合わせいただきました内容を確認の上、ご連絡をさせていただきます。<br />いましばらくお待ちくださいますよう、よろしくお願い申し上げます。</p>
              <div className="form__btn list">
                <div className="list__item">
                  <a className="btn btn--form btn--submit" onClick={() => returnTop()}>トップに戻る</a>
                </div>
              </div>
            </div>
            <div className="copy">© Design CREHA all right reserved.</div>
          </div>
        </div>
      }
      </>
  );
}

export default ContactContainer;
