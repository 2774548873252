import React, { useEffect, useState } from "react";
import { Helmet  } from 'react-helmet-async'


type Props = {
  title: string
}

const Head =(props: Props) => {
  const {title} = props
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
export default Head;
