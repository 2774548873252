import React, { useRef, useState, useEffect } from "react";
import { useNavigate }  from "react-router-dom";



const WorksContainer =  ({show=false, locationPath = "/", setLocationPath = (path: any) => {} , navigate = (path: any) => {} }) => {
  const [hidePage, setHidePage]  = useState(false);
  function returnTop() {
    setHidePage(true);
  }
  function removeHide(elm: any) {
    if(elm.target.classList.contains("mainLogo") && hidePage) {
      navigate("/");
      setHidePage(false);
    }
  }


  return (
      <div className={"container container__col1 container-works" + (locationPath === "/works" ? " show" : "") + (hidePage ? " hide" : "" )}>
        <div className="main main__works">
          <div className="mainLogo" onClick={() => returnTop()} onAnimationEnd={(e) => removeHide(e)}><img src="img/sidelogo.png" alt="design creha" /></div>
          <div className="mainClose" onClick={() => returnTop()}>
            <span></span>
            <span></span>
          </div>
          <div className="mainHead">WORKS</div>
          <div className="worksBlock">
            <h2 className="worksHead">制作内容</h2>
            <ul className="worksList">
              <li>● 販促用の広告企画（チラシ・パンフ）</li>
              <li>● 通販専門の広告企画</li>
              <li>● 雑誌やパンフレットなどのエディトリアルデザイン</li>
              <li>● ブランドビジュアル<br />（ロゴ・ロゴタイプ・パッケージ・サイン・モニュメント）</li>
              <li>● ライティングワーク</li>
              <li>● イラストレーション </li>
            </ul>
          </div>
          <div className="worksSub">
            <h3 className="worksSubHead">Design Collection</h3>
            <p className="worksSubDesc">過去に制作した作品をピックアップしたデザインコレクション（PDF）をご用意しています。<br />ご希望の方は「お問い合わせフォーム」よりその旨お伝えいただければ送付いたします。</p>
          </div>
          <div className="copy">© Design CREHA all right reserved.</div>
        </div>
      </div>
  );
}

export default WorksContainer;
