import React, { useRef, useState, useEffect } from "react";
import TopAnimation from "./TopAnimation";
import TopAnimationComplete from "./TopAnimationComplete";
import TopContents from "./TopContents";
import TopMenu from "./TopMenu";
import { useLocation } from "react-router-dom"
import useLocationChange from './useLocationChange'

const TopContainer = ( { navigate = (path: any) => {}, setShowMenu = (flg: any) => {}, showMenu = false }) => {
  const location = useLocation();
  const [useAnimation, setUseAnimation]  = useState(true);
  const [textAnimationPlay, setTextAnimationPlay]  = useState(false);
  const [textAnimationMov, setTextAnimationMov]  = useState(false);
  const [textAnimationBeige, setTextAnimationBeige]  = useState(false);
  const [hideMenu, setHideMenu]  = useState(false);
  const handleSetTextAnimationPlay = (path: any): void => setTextAnimationPlay(path);
  const handleSetTextMov = (flg: any): void => setTextAnimationMov(flg);
  const handleSetTextAnimationBeige = (path: any): void => setTextAnimationBeige(path);
  const handleSetUseAnimation = (flg: any): void => setUseAnimation(flg);
  const handleSetHideMenu = (flg: any): void => setHideMenu(flg);
  const restartAnimation = () =>  {
    //setShowMenu(false);
    setHideMenu(true);
    setTimeout(function(){
      setShowMenu(false);
      setUseAnimation(true);
      setHideMenu(false);
    }, 1000);
  }
  useEffect(() => {
      if(location.pathname === "/") {
        setUseAnimation(true);
      } else {
        setUseAnimation(false);
      }
  }, []);

    return (
      <div className={textAnimationPlay ? "container container__top container__green" : textAnimationMov ? "container container__top container__mov" : hideMenu ? "container container__top hide" : textAnimationBeige ? "container container__top container__beige" : "container container__top"}>
        {showMenu && 
        <div className="mainClose" onClick={() => restartAnimation()}>
          <span></span>
          <span></span>
        </div>
        }
        <TopMenu showMenu = {showMenu} navigate={navigate} setShowMenu={setShowMenu} setHideMenu={handleSetHideMenu} hideMenu={hideMenu}/>
        { useAnimation
          ? <TopAnimation setShowMenu = {() => setShowMenu(true)} setTextAnimationPlayParent={handleSetTextAnimationPlay} setTextAnimationBeige={handleSetTextAnimationBeige} setTextAnimationMov={handleSetTextMov} setUseAnimation={handleSetUseAnimation}/>
          : <TopAnimationComplete setShowMenu = {() => setShowMenu(true)} />
        }
      </div>
    );


}
export default TopContainer;
