import React, { useRef, useState, useEffect } from "react";
import { useNavigate }  from "react-router-dom";



const ProfileContainer =  ({show=false, locationPath = "/", setLocationPath = (path: any) => {} , navigate = (path: any) => {} }) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false)
  const executeScroll = (): void => myRef.current?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  }
);
  const [hidePage, setHidePage]  = useState(false);
  function returnTop() {
    setHidePage(true);
  }
  function removeHide(elm: any) {
    if(elm.target.classList.contains("main") && hidePage) {
      navigate("/");
      setHidePage(false);
    }
  }
  const toggleVisibility = () => {
      if(myRef.current?.scrollTop == 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
 }
 useEffect(() => {
     myRef.current?.addEventListener('scroll', toggleVisibility)
       return () => window.removeEventListener('scroll', toggleVisibility)
 }, [])


  return (
         <div className={"container container-profile" + (locationPath === "/profile" ? " show" : "") + (hidePage ? " hide" : "" )}>
           <div className="side">
             <div className="sideLogo" onClick={() => returnTop()}><img src="img/sidelogo.png" alt="design creha" /></div>
             <div className="sideCatch sideCatch__profile"><img src="img/copy__profile.png" alt="デザインくれは" /></div>
             <div className="sideCopy">© Design CREHA all right reserved.</div>
           </div>
           <div className="main main__profile" ref={myRef} onAnimationEnd={(e) => removeHide(e)}>
             <div className="mainClose" onClick={() => returnTop()}>
               <span></span>
               <span></span>
             </div>
             <div className={isVisible ? "gotop show" : "gotop"} onClick={() => executeScroll()}><img src="img/icon__gotop.png" /></div>
             <div className="mainHead">PROFILE</div>
             <div className="profileBlock">
               <div className="profileImg"><img src="img/profile__hiroki.jpg" /></div>
               <p className="profileName">代表　吉嶺 広記</p>
               <p className="profilePost">Graphic Designer / Creative Derector</p>
               <p className="profileDesc">20代、デザイン事務所での修行が始まり、その中で尊敬する師匠に学ぶ。その後、印刷会社でのチーフデザイナーを経て40歳で独立。約30年、グラフィックデザインの世界で活動。紙媒体の広告全般からロゴマーク制作、パッケージやwebデザインなど多岐に渡って実績を積み、ここ数年は有名通販会社数社のパートナーとしても販促企画やデザインアドバイスなどに携わる。2021年より福岡でも活動中。</p>
               <div className="profileOther">
                 <p>主な受賞歴<br />かごしまデザインフェア デザインコンペ 一般部門 入選多数<br />かごしまデザインフェア デザインコンペ KIF部門 入選多数<br />かごしまデザインフェア デザインコンペ KIF部門 優秀賞</p>
                 <p>メディア<br />月刊「アドフラッシュ」　デザイン総合誌「PROFILE」</p>
                 <p>鹿児島県生まれ 血液型 A<br />好きなこと：ギター・ピアノ・ビリヤード </p>
               </div>
             </div>
             <div className="profileBlock">
               <div className="profileImg"><img src="img/profile__shoko.jpg" /></div>
               <p className="profileName">吉嶺 晶子</p>
               <p className="profilePost">Graphic Designer / Illustrator</p>
               <p className="profileDesc">短大卒業後、印刷会社へ入社。フォントオペレーター、DTPオペレーターを経て24歳の時にデザイン部へ配属。グラフィックデザインの世界で必要なイラストは自分で描きながら多業種のデザインを手がける。約8年半勤めた会社を退職後2003年に独立。その後もデザイナーとして企画立案の段階から参加して数多くの案件に携わる。また自身のイラストを活かした出版物も刊行されるなど多方面で活動中。</p>
               <div className="profileOther">
                 <p>受賞歴<br />かごしまデザインフェア デザインコンペ 一般部門 入選多数<br />かごしまデザインフェア デザインコンペ KIF部門 入選多数</p>
                 <p>メディア<br />月刊「アドフラッシュ」　デザイン総合誌「PROFILE」 </p>
                 <p>長野県生まれ鹿児島育ち  血液型 O<br />好きなこと：GAME・プチ菜園・寝ること</p>
                 <p>illustrator site   <a href="https://d-creha.com/sy-oekaki/">https://d-creha.com/sy-oekaki/</a></p>
               </div>
             </div>
             <footer>
               <div className="footerLogo"><img src="img/logo__footer.png" /></div>
               <p>E-mail: hiroki@d-creha.com　TEL 099-259-7363<br />〒890-0047 鹿児島県鹿児島市常盤２丁目6-20-503</p>
             </footer>
           </div>
         </div>


  );
}

export default ProfileContainer;
